import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';
import { CONFIGURAZIONE, Configurazione } from './app';

@Component({
  selector: 'app-photo-dialog',
  templateUrl: './photo-dialog.component.html'
})
export class PhotoDialogComponent {
  src: any;

  constructor(
    private storage : AngularFireStorage,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    public dialogRef: MatDialogRef<PhotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public path: string) {
      this.src = `https://storage.googleapis.com/${(<any>this.storage.storage.app.options).storageBucket}/${configuration.liid}/${path}`;
     }

  public Close(result : boolean) {
    this.dialogRef.close(result);
  }
}