import { Component, Inject, OnInit } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from 'src/shared/app';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  sc: any;
  sh: any;
  links = [];
  map: any;

  constructor( @Inject(CONFIGURAZIONE) public configuration: Configurazione,
  @Inject(SERVIZI) public services: Services) {
    this.sc = this.configuration.setup_configurazione;
    this.map = `https://maps.google.com/?z=17&q=${this.sc.LocationLatitude},${this.sc.LocationLongitude}&output=svembed`;
    //this.map = "https://maps.google.com/?z=17&q=39.31927950059986,16.239093457671004&output=svembed";
    this.sh = this.configuration.setup_homepage;
    this.links = [
      // { path: '/home', header: 'Home', show: () => true },
      { path: '/orari', header: 'Orari', show: () => this.areThereFasce()},
      { path: '/contatti', header: 'Contattaci', show: () => true },
      { path: '/privacy', header: 'Informativa Privacy', show: () => true }
    ];
  }

  ScrollTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  private areThereFasce(): boolean {
    let flag: boolean = (this.configuration.setup_fascia.length != 0);
    flag = flag || (this.configuration.setup_fascia_chiusura.length != 0);
    flag = flag || (this.configuration.setup_fascia_asporto.length != 0);
    flag = flag || (this.configuration.setup_fascia_consegna.length != 0);
    return flag;
  }

}
