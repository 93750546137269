import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Configurazione, CONFIGURAZIONE, Services, SERVIZI } from './app';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-crea-dialog',
  templateUrl: './crea-dialog.component.html',
  animations: [
    trigger(
      'inOutAnimation1',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class CreaDialogComponent {
  filter: string;
  _mp: any;
  _mps: any[];
  _mcis: any[];
  Qta: number = 1;
  _add: any;

  constructor(
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services,
    public dialogRef: MatDialogRef<CreaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this._mps = data.mps;
    this._mcis = data.mcis;
    this._add = data.add;
  }

  Add() {
    this.filter = null;
    const mcis = this._mcis.filter(_it => _it.Selected === true);
    var mp = JSON.parse(JSON.stringify(this._mp));
    Object.assign(mp, { Qta: 1, MCIs : mcis });
    this._add(mp);
    this.dialogRef.close();
  }

  public Close(result: boolean) {
    this.dialogRef.close(result);
  }
}