import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { analytics } from 'firebase-functions/v1';
import { CONFIGURAZIONE, Configurazione, SERVIZI, Services, MenuPiatto } from './app';

@Component({
  selector: 'app-dettaglio-menu-piatto',
  templateUrl: './dettaglio-menu-piatto.component.html',
  styles: [
  ]
})
export class DettaglioMenuPiattoComponent implements OnInit {
  filter: string;
  _mp: any;
  mcis: any;
  varianti: any;
  tmpCart : any;
  tmpRiepilogo: any;
  src: any;
  ingredienti: any;
  prezzo: number;
  nmcis: any;
  sc: any;
  header: boolean;
  self: boolean;

  constructor(
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services,
    public dialogRef: MatDialogRef<DettaglioMenuPiattoComponent>,
    private snackbar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this._mp = Object.assign({}, data.mp);;
    this.src = data.src;
    this.tmpCart = data.tmpCart;
    this.mcis = data.mcis.filter(_it => _it.Tipo == 0);
    this.varianti = data.mcis.filter(_it => _it.Tipo == 1);
    this.nmcis = data.nmcis;
    this.sc = data.sc;
    this.self = data.self;
    this.tmpRiepilogo = data.tmpRiepilogo;
    this.header = false;
    this.filter = null;
  }

  ngOnInit(): void {
    this.ingredienti = this.Ingredienti(this._mp)
    this._mp.Qta = 1;
    this.prezzo = this._mp.Prezzo;
  }

  Ingredienti(_mp) {
    const mis = this.configuration.menu_piatto_ingrediente.filter(_it => _it.Piatto == _mp.Guid).map(_it => {
      return this.configuration.menu_ingrediente.find(__it => __it.Guid == _it.Ingrediente).Nome;
    });
    return mis;
  }

  Rimuovi(){
    this._mp.Qta > 1 ? this._mp.Qta = this._mp.Qta - 1 : this._mp.Qta;
  }

  Aumenta(){
    this._mp.Qta = this._mp.Qta + 1
  }

  Add(){
    const nmcis = this.nmcis.filter(_it => _it.Selected === true);
    const mcis = this.mcis.filter(_it => _it.Selected === true);
    const vars = this.varianti.filter(_it => _it.Selected === true);
    const MCI = mcis.concat(nmcis).concat(vars);
    var mp = JSON.parse(JSON.stringify(this._mp));
    Object.assign(mp, { Qta: this._mp.Qta, MCIs : MCI });
    if(this.self) 
      this.PortataAggiungiSelf(mp);
    else this.PortataAggiungi(mp);
  }

  check(mi: any){
    mi.Selected = !mi.Selected;
    if(mi.Tipo === 1)
    this.varianti = this.varianti.map(_it => {
      if (_it.Guid !== mi.Guid) {
        _it.Selected = false;
      }
      return _it;
    });
    this.filter = null;
    this.UpdatePrezzo();
  }

  UpdatePrezzo(){
    this.prezzo = this._mp.Prezzo;
    const mcis = this.mcis.filter(_it => _it.Selected === true);
    const vars = this.varianti.filter(_it => _it.Selected === true);
    const items = mcis.concat(vars);
    for( let mc of items){
      this.prezzo += mc.Prezzo;
    }
    if(this.sc.SottoPrezzoBase){
      const nmcis = this.nmcis.filter(_it => _it.Selected === true);
      for( let nmc of nmcis){
        this.prezzo -= nmc.Prezzo;
      }
    }
  }

  PortataAggiungi(mp: any) {
    const comanda_portate = <any[]>this.tmpCart.comanda_portate;
    var dst;
    // Se è una portata modificata creo una nuova portata nel carrello
    if(mp.MCIs && mp.MCIs.length > 0){
      var el = this.checkPortataModificata(mp,comanda_portate)
      if(el == -1){
        dst = Object.assign({ Qta: mp.Qta }, mp);
        comanda_portate.push(dst);
      }
      else {
        dst = comanda_portate[el];
        dst.Qta = dst.Qta + mp.Qta;
      }
    }
    else {
      dst = comanda_portate.find(_it => _it.Guid === mp.Guid && _it.MCIs == 0);
      if (dst) {
        dst.Qta = dst.Qta + mp.Qta;
      } else {
        dst = Object.assign({ Qta: mp.Qta }, mp);
        comanda_portate.push(dst);
    }
    }
    localStorage.setItem('carrello', JSON.stringify(this.tmpCart));
    const _snackbar = this.snackbar.open(`Portata aggiunta: ${dst.Nome} X ${dst.Qta}`, 'Carrello');
    _snackbar.onAction().subscribe(() => {
      this.router.navigate(['carrello']);
    });
    this.dialogRef.close(false);
  }

  PortataAggiungiSelf(mp: any){
    const comanda_portate = <any[]>this.tmpRiepilogo.comanda_portate;
    var dst;
    // Se è una portata modificata creo una nuova portata nel carrello
    if(mp.MCIs && mp.MCIs.length > 0){
      var el = this.checkPortataModificata(mp,comanda_portate)
      if(el == -1){
        dst = Object.assign({ Qta: mp.Qta, Progressivo: 0, Changed: new Date() }, mp);
        comanda_portate.push(dst);
      }
      else {
        dst = comanda_portate[el];
        dst.Qta = dst.Qta + mp.Qta;
      }
    }
    else {
      dst = comanda_portate.find(_it => _it.Guid === mp.Guid && _it.MCIs == 0);
      if (dst) {
        dst.Qta = dst.Qta + mp.Qta;
      } else {
        dst = Object.assign({ Qta: mp.Qta, Progressivo: 0, Changed: new Date() }, mp);
        comanda_portate.push(dst);
    }
    }
    localStorage.setItem('riepilogo', JSON.stringify(this.tmpRiepilogo));
    const _snackbar = this.snackbar.open(`Portata aggiunta: ${dst.Nome} X ${dst.Qta}`, 'Riepilogo');
    _snackbar.onAction().subscribe(() => {
      this.router.navigate(['/r',this.tmpRiepilogo.coid]); 
    });
    this.dialogRef.close(false);
  }


  public Close(result: boolean) {
    this.dialogRef.close(result);
  }

  checkPortataModificata(mp: any,comanda_portate: any): any{
    const portate = Object.assign([], comanda_portate);
    for( var i = 0; i< portate.length; i++){
      if (portate[i].MCIs && portate[i].MCIs.length > 0) {
        if (this.areEquals(mp.MCIs, portate[i].MCIs)) {
          return i;
        }
      }
    }
    return -1;
  }

  areEquals(a: any, b: any){
    if (a.size !== b.size) {
      return false;
    }
    return JSON.stringify(a)==JSON.stringify(b);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    //   console.log(event.target.scrollHeight);
    // }
    if (!this.header && event.target.offsetHeight + event.target.scrollTop >= 825) {
      this.header = true;
    }
    if (this.header && event.target.offsetHeight + event.target.scrollTop < 825) {
      this.header = false;
    }
  }

}
