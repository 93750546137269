import { Component, AfterContentInit, ViewEncapsulation, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI, SetupConfigurazione } from '../shared/app';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IPayPalConfig } from 'ngx-paypal';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { DettaglioFatturazioneComponent } from 'src/shared/dettaglio-fatturazione.component';

@Component({
  selector: 'app-order1',
  templateUrl: './order.component.html',
  encapsulation: ViewEncapsulation.None
})

export class Order1Component implements AfterContentInit {

  public order: any = {};
  orderPayPal: any = {};
  dMessageRef: any;
  dOrderRef: any;
  coid: any;
  public ordini_uscite: any;
  PayPalConfig?: IPayPalConfig;
  sc: SetupConfigurazione;
  token: string;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, 
    private snackbar: MatSnackBar,
    private messaging: AngularFireMessaging,
    private router: Router,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.coid = this.route.snapshot.params['coid'];
    this.sc = this.configuration.setup_configurazione;
    this.order = {};
    this.ordini_uscite = [];
  }

  async ngAfterContentInit() {
    this.services.SelfLoad();
    this.dMessageRef = this.services.firestore.collection("messaggi").doc(this.coid);
    this.dOrderRef = this.services.firestore.collection("comanda_portata").doc(this.coid);
    this.dOrderRef.snapshotChanges().subscribe(_doc => {
      this.order = _doc.payload.data();
      this.RefreshOrdine();
      this.SetupPaypal();
    });
    this.dMessageRef.set({ Command: "o" });
  }

  SetupPaypal(){
    this.messaging.requestToken.subscribe(token => {
      console.log('Token: ' + token);
      this.token = token;
    });
    if (this.sc.PaypalClientId) {
      this.PayPalConfig = {
        currency: 'EUR',
        clientId: this.sc.PaypalClientId,
        createOrderOnClient: () => {
          this.Prepare();
          return this.services.PayPalOrderSelf(this.orderPayPal);
        },
        advanced: {
          commit: 'true'
        },
        style: {
          label: "checkout",
          layout: 'vertical',
          shape: "pill",
          size: "large",
        },
        onClientAuthorization: async (_auth) => {
          Object.assign(this.order, {
            paypalId: _auth.id,
            paypalCreationDate: _auth.create_time,
            paypalPayerId: _auth.payer.payer_id,
            paypalEmailAddress: _auth.payer.email_address
          });
          await this.Finalize();
        }
      };
    }
  }

  RefreshOrdine(){
    this.ordini_uscite = [];
    var portate = this.order.Portate.length > 0 ? this.order.Portate : [];
    var divise = this.order.Divise.length > 0 ? this.order.Divise : [];
    var comanda_portate = portate.concat(divise)
    comanda_portate.sort((a, b) => (a.Progressivo < b.Progressivo) ? -1 : 1);
    var uscita = -1;
    var obj = null;
    comanda_portate.forEach(el => {
      if(el.Progressivo != uscita){
        obj = { type: 1, content: `${el.Progressivo + 1}`};
        uscita = el.Progressivo;
        this.ordini_uscite.push(obj);
        obj = { type: 2, content: el, divisa: el.Divisa};
        this.ordini_uscite.push(obj);
      }
      else{
        obj = { type: 2, content: el, divisa: el.Divisa};
        this.ordini_uscite.push(obj);
      }
    });
  }

  Prepare(): void {
    const order = Object.entries(this.order).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
    this.orderPayPal = Object.assign(order, {
      ora: parseInt(this.order.ora),
      token: this.token || '',
      stato: 0,
      sync: true
    });
  }

  async Sync() {
    const question = this.dialog.open(ConfirmationDialogComponent, {
      // 
      panelClass: 'dialog-container-custom',
      data: "Inviare la richiesta di preconto?"
    });
    const confirm = await question.afterClosed().toPromise();
    if (!confirm) {
      return;
    }
    this.dMessageRef.set({ Command: "p" }).then(() => {
      this.snackbar.open(`Richiesta di preconto inviata`, null, {
        duration: 2000,
        horizontalPosition: "center",
        verticalPosition: "top"
      }).afterDismissed().subscribe(() => {
        this.services.prompt(false);
      });
    });
  }

  async Finalize() {
    const question = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'dialog-container-custom',
      data: "Desidera richiedere la fattura?"
    });
    const response = await question.afterClosed().toPromise();
    if(response){
      await this.dialog.open(DettaglioFatturazioneComponent, {
        hasBackdrop: true,
        autoFocus: false,
        panelClass: 'dialog-container-custom',
        data: {
          message: this.dMessageRef
        }
      });
    }
    else 
      this.dMessageRef.set({ Command: "c" }).then(() => {
        this.snackbar.open(`Pagamento inviato, comanda completata`, null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top"
        }).afterDismissed().subscribe(() => {
          this.services.prompt(false);
        });
      });
  }

}
