import { Component, AfterContentInit, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { PhotoDialogComponent } from 'src/shared/photo-dialog.component';
import { CreaDialogComponent } from 'src/shared/crea-dialog.component';
import { _MatOptionBase } from '@angular/material/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { DettaglioMenuPiattoComponent } from 'src/shared/dettaglio-menu-piatto.component';

@Component({
  selector: 'app-menu1',
  templateUrl: './menu.component.html',
  animations: [
    trigger(
      'inOutAnimation1',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class Menu1Component implements AfterContentInit {
  _mis: any;
  dMessageRef: any;
  coid: any;
  src: string;
  _mpis: any;
  tmpRiepilogo: any;
  public order: any = {};
  dOrderRef: any;

  
  ngAfterContentInit(): void {
    this.dOrderRef = this.services.firestore.collection("comanda_portata").doc(this.coid);
    this.dOrderRef.snapshotChanges().subscribe(_doc => {
      this.order = _doc.payload.data();
      if (this.order.Attivo == false){
        this.router.navigate(['/o',this.coid]);
      }
    });
    this.mcs = this.configuration.menu_categoria.filter(_mc => _mc.EnableOnLine);
    this._mis = this.configuration.menu_ingrediente;
    this.services.SelfLoad();
  }

  async Foto(mp) {
    await this.dialog.open(PhotoDialogComponent, {
      hasBackdrop: false,
      panelClass: 'photo-panel',
      data: mp.Foto
    });
  }

  public mcs: any[] = [];
  mc: any = null;

  constructor(private route: ActivatedRoute, private dialog: MatDialog,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    private storage : AngularFireStorage,
    private router: Router,
    @Inject(SERVIZI) public services: Services) {
    this.coid = this.route.snapshot.params['coid'];
    this.dMessageRef = this.services.firestore.collection("messaggi").doc(this.coid);
    this.src = `https://storage.googleapis.com/${(<any>this.storage.storage.app.options).storageBucket}/${configuration.liid}/`;
    this._mpis = configuration.menu_piatto_ingrediente;
    const riepilogo = localStorage.getItem('riepilogo');
    if (riepilogo) {
      this.tmpRiepilogo = JSON.parse(riepilogo);
      this.CheckRiepilogo();
    } else {
      this.tmpRiepilogo = { comanda_portate: [], coid: this.coid };
    }
    this.Can();
  }

  Piatti(_mc) {
    const mps1 = this.configuration.self_mps.filter(_it => _it.Categoria == _mc.Guid);
    const mps2 = this.configuration.self_mpcs.filter(_it => _it.Categoria == _mc.Guid);
    return mps2.concat(mps1);
  }

  Ingredienti(_mp) {
    const mis = this._mpis.filter(_it => _it.Piatto == _mp.Guid).map(_it => {
      return this._mis.find(__it => __it.Guid == _it.Ingrediente).Nome;
    });
    return mis;
  }

  Save() {
    localStorage.setItem('riepilogo', JSON.stringify(this.tmpRiepilogo));
  }

  CheckRiepilogo(){
    if(this.tmpRiepilogo.coid != this.coid){
      this.tmpRiepilogo.comanda_portate = [];
      this.tmpRiepilogo.coid = this.coid;
      this.Save();
    }
  }

  Can() {
    return this.tmpRiepilogo.comanda_portate.length > 0;
  }

  Riepilogo(){
    this.router.navigate(['/r',this.coid]);
  }

  ContentRiepilogo(){
    var totale = 0;
    if(this.tmpRiepilogo.comanda_portate.length > 0){
      const portate = this.tmpRiepilogo.comanda_portate;
      totale = portate.map( el => el.Qta).reduce((sum , current) => sum + current);
    }
    return totale;
  }

  Qta(mc) {
    var qta1 = this.configuration.self_mps.filter(_it => _it.Categoria === mc.Guid && _it.Qta > 0).reduce((acc, cur) => acc + cur.Qta, 0);
    var qta2 = this.configuration.self_mpcs.filter(_it => _it.Categoria === mc.Guid && _it.Qta > 0).reduce((acc, cur) => acc + cur.Qta, 0);
    return qta1 + qta2 > 0 ? qta1 + qta2 : "";
  }

  // Non usato
  async Crea(_mc) {
    const mps = this.configuration.menu_piatto.filter(_it => _it.EnableSelf && _it.Categoria === _mc.Guid && _it.Base === true);
    const _mcis = this.configuration.menu_categoria_ingrediente.filter(_it => _it.Categoria === _mc.Guid && this._mis.some(_mi => _mi.Guid === _it.Ingrediente && _mi.Base === true));
    const mcis = _mcis.map(mi => Object.assign(this.configuration.menu_ingrediente.filter(_it => _it.Guid === mi.Ingrediente)[0], { Prezzo: mi.Prezzo, Selected: false }));
    await this.dialog.open(CreaDialogComponent, {
      hasBackdrop: true,
      panelClass: 'crea-panel',
      height: '625px',
      data: {
        mps: mps,
        mcis: mcis,
        add: (_mpc) => {
          Object.assign(_mpc, { Id: this.configuration.self_mpcs.length });
          this.configuration.self_mpcs.push(_mpc);
          this.services.SelfSave();
        }
      }
    });
  }

  // Non usato
  CanCrea(_mc) {
    if (!this._mis) {
      return false;
    }
    return this.configuration.self_mps.filter(_it => _it.Categoria == _mc.Guid && _it.Base === true).length > 0;
  }

  async Dettaglio(_mp){
    const _mcis = this.configuration.menu_categoria_ingrediente.filter(_it => _it.Categoria === this.mc.Guid && this._mis.some(_mi => _mi.Guid === _it.Ingrediente));
    const mis = this._mpis.filter(_it => _it.Piatto == _mp.Guid);
    const mcis = _mcis.filter(_el => mis.some(_it => _it.Ingrediente === _el.Ingrediente) === false).map(mi => Object.assign(this.configuration.menu_ingrediente.filter(_it => _it.Guid === mi.Ingrediente)[0], { Prezzo: mi.Prezzo || 0, Selected: false }));
    const nmcis = mis.map(mi => Object.assign(this.configuration.menu_ingrediente.filter(_it => _it.Guid === mi.Ingrediente)[0], 
    { Prezzo: this.configuration.menu_categoria_ingrediente.find(_el =>_el.Ingrediente === mi.Ingrediente) ? 
      this.configuration.menu_categoria_ingrediente.find(_el =>_el.Ingrediente === mi.Ingrediente).Prezzo : 0,
      Selected: false 
    }));
    await this.dialog.open(DettaglioMenuPiattoComponent, {
      hasBackdrop: true,
      autoFocus: false,
      panelClass: 'dialog-container-custom',
      data: {
        mp: _mp,
        src: this.src,
        mcis: mcis,
        nmcis: nmcis,
        sc: this.configuration.setup_configurazione,
        self: true,
        tmpRiepilogo: this.tmpRiepilogo
      }
    });
  }

}
