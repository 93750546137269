export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDndXKz1WmeweO8hnZVQR6dV0lL4rRkN94",
    authDomain: "michele-4-0.firebaseapp.com",
    databaseURL: "https://michele-4-0.firebaseio.com",
    projectId: "michele-4-0",
    storageBucket: "michele-4-0.appspot.com",
    messagingSenderId: "177694569735",
    appId: "1:177694569735:web:1a439fd70f7b538061c1a2",
    measurementId: "G-SZXZX13T6W"
  }
};