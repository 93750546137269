import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CONFIGURAZIONE, Configurazione, SERVIZI, Services } from 'src/shared/app';

@Component({
  selector: 'app-rispondi-dialog',
  templateUrl: './rispondi-dialog.component.html'
})
export class RispondiDialogComponent {

  prn: any;
  public Comments = ['Siamo spiacenti, ma per esaurimento dei posti disponibili, non possiamo accettare la sua prenotazione.',
    'Siamo spiacenti, ma a causa delle numerose richieste non è più possibile ricevere ulteriori prenotazioni. Le consigliamo di passare direttamente al locale i tavoli si liberano continuamente. Sarà nostra premura farLa accomodare nel più breve tempo possibile.',
    'Siamo spiacenti, ma a causa di avverse condizioni metereologiche non possiamo accettare la sua prenotazione.',
    'Siamo spiacenti, ma a causa di un imprevisto non possiamo accettare la sua prenotazione.'];

  constructor(
    public dialogRef: MatDialogRef<RispondiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
      this.prn = data.prn;
     }
  
  // 1 --> accetta, 2 --> rifiuta, 3 --> esci
  public Close(result : number) {
    this.dialogRef.close(result);
  }

}
