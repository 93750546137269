import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { orderBy } from 'lodash';

@Pipe({
  name: 'm40fasce'
})
export class M40FascePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const fasce = <any[]>value;
    if (!fasce) {
      return [];
    }
    const data = args[0];
    const outputs = [];
    /**/
    const weekDay = moment(data).weekday();
    const los = fasce.filter(_it => (_it.Giorno & (1 << (weekDay))) > 0);
    /**/
    var start = 0;
    if (moment(data).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      return outputs;
    }
    if (moment(data).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      const now = moment();
      start = now.hours() * 60 + now.minutes();
    }
    /**/
    for (const lo of los) {
      var i = lo.Inizio;
      var f = lo.Fine || lo.Inizio;
      for (var t = i; t <= f; t = t + 15) {
        if (t >= (start + lo.Salvaguardia)) {
          outputs.push(t);
        }
      }
    }
    return outputs.sort((a, b) => a - b);
  }
}
