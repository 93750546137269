import { Component, AfterContentInit, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { OkDialogComponent } from 'src/shared/ok-dialog.component';
import { _MatOptionBase } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timeout } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/shared/confirmation-dialog.component';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  animations: [
    trigger(
      'inOutAnimation1',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class ResumeComponent implements AfterContentInit {
  notes: string;
  dMessageRef: any;
  coid: any;
  tmpRiepilogo: any;
  public order: any = {};
  dOrderRef: any;
  portate_riepilogo_uscite = [];
  min_uscita: number;
  public ultima_uscita: any = -1;
  check: boolean;


  constructor(private route: ActivatedRoute, private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private router: Router,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.coid = this.route.snapshot.params['coid'];
    this.dMessageRef = this.services.firestore.collection("messaggi").doc(this.coid);
    const riepilogo = localStorage.getItem('riepilogo');
    if (riepilogo) {
      this.tmpRiepilogo = JSON.parse(riepilogo);
      this.check = true;
    } else {
      this.check = false;
      this.tmpRiepilogo = { comanda_portate: [], coid: this.coid };
    }
  }

  ngAfterContentInit(): void {
    // this.services.SelfLoad();
    this.dMessageRef.set({ Command: "o" });
    this.dOrderRef = this.services.firestore.collection("comanda_portata").doc(this.coid);
    this.dOrderRef.snapshotChanges().subscribe(_doc => {
      this.order = _doc.payload.data();
      this.ultima_uscita = this.order.UltimaUscita ? this.order.UltimaUscita : 0;
      if (this.order.Attivo == false){
        this.router.navigate(['/o',this.coid]);
      }
      if(this.check) this.CheckRiepilogo();
    });
  }

  RefreshRiepilogo(){
    this.portate_riepilogo_uscite = [];
    this.tmpRiepilogo.comanda_portate.sort((a, b) => (a.Progressivo < b.Progressivo) ? -1 : 1);
    const comanda_portate = this.tmpRiepilogo.comanda_portate;
    var uscita = Number.MIN_SAFE_INTEGER;
    var item_index = 0;
    var obj = null;
    const a = "a";
    this.min_uscita = Number.MAX_SAFE_INTEGER;
    comanda_portate.forEach(el => {
      if( el.Progressivo != uscita){
        obj = { type: 1, content: `${this.ultima_uscita + el.Progressivo + 1}`};
        uscita = el.Progressivo;
        this.min_uscita = uscita + this.ultima_uscita + 1 < this.min_uscita ? uscita + this.ultima_uscita + 1 : this.min_uscita;
        this.portate_riepilogo_uscite.push(obj);
        obj = { type: 2, content: el, index: item_index};
        this.portate_riepilogo_uscite.push(obj);
        item_index++;
      }
      else{
        obj = { type: 2, content: el, index: item_index};
        this.portate_riepilogo_uscite.push(obj);
        item_index++;
      }
    });
  }

  DisabledRiduciUscita( uscita: number ){
    if(uscita + this.ultima_uscita === 0) return true;
    // else if(uscita === this.max_uscita) return false;
    // return this.isSoloUnElemento(uscita);
    return false;
  }

  isSoloUnElemento(uscita: number){
    return this.tmpRiepilogo.comanda_portate.filter(el => el.Progressivo === uscita).length <= 1;
  }

  RiduciUscita(idx: number){
    if (idx < 0) {
      return;
    }
    const comanda_portate = <any[]>this.tmpRiepilogo.comanda_portate;
    var dst = comanda_portate[idx]
    if (dst) {
      dst.Progressivo = dst.Progressivo - 1;
    }
    this.Save();
    this.RefreshRiepilogo();
  }

  AumentaUscita(idx: number){
    if (idx < 0) {
      return;
    }
    const comanda_portate = <any[]>this.tmpRiepilogo.comanda_portate;
    var dst = comanda_portate[idx]
    if (dst) {
      dst.Progressivo = dst.Progressivo + 1;
    }
    this.Save();
    this.RefreshRiepilogo();
  }

  // IncrementaProgLista(comanda_portate : any){
  //   comanda_portate.map(el => el.Progressivo += 1);
  // }

  CheckRiepilogo(){
    if(this.tmpRiepilogo.coid != this.coid){
      this.tmpRiepilogo.coid = this.coid;
      this.SelfClean();
    }
    else {
      this.RefreshRiepilogo();
    }
  }

  async Clean(){
    const question = await this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'dialog-container-custom',
      data: "Eliminare tutte le portate?"
    }).afterClosed().toPromise();
    if(!question){
      return;
    }
    // this.services.SelfClean();
    this.SelfClean()
  }

  private SelfClean(){
    this.tmpRiepilogo.comanda_portate = [];
    this.Save();
    this.RefreshRiepilogo();
  }


  Piatti() {
    // const mps1 = this.configuration.self_mps.filter(_it => _it.Qta > 0);
    // const mps2 = this.configuration.self_mpcs.filter(_it => _it.Qta > 0);
    // return mps2.concat(mps1);
    return this.tmpRiepilogo.comanda_portate;
  }

  async Sync() {
    // const mps1 = this.configuration.self_mps.filter(_it => _it.Qta > 0);
    // const mps2 = this.configuration.self_mpcs.filter(_it => _it.Qta > 0);
    // const mps = mps2.concat(mps1);
    const mps = this.tmpRiepilogo.comanda_portate;
    const min = mps.reduce((previous, current) => {
      return current.Progressivo < previous.Progressivo ? current : previous;
    });
    const progressivoPartenza = this.ultima_uscita + min.Progressivo;
    await this.dMessageRef.set({ Command: "n", Lines: mps, Notes: this.notes || '', ProgressivoPartenza: progressivoPartenza });
    /*
    for (const _it of this.configuration.self_mps) {
      _it.Qta = 0;
    }
    this.configuration.self_mpcs = [];
    */
    await this.snackbar.open(`Il tuo ordine è stato inviato.`, null, {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "top"
    }).afterDismissed().toPromise();
    this.tmpRiepilogo.comanda_portate = [];
    // this.isUltimaUscitaModified = false;
    this.Save();
    // this.services.SelfClean();
    //this.services.SelfClean();
    /*
    setTimeout(() => {
      this.services.prompt(false);
    }, 5000);
    */
    await this.router.navigate(['o', this.coid]);
  }

  async PortataRimuovi(_mp, idx: number) {
    const comanda_portate = <any[]>this.tmpRiepilogo.comanda_portate;
    if (idx < 0) {
      return;
    }
    var dst = comanda_portate[idx]
    if (dst.Qta > 1) {
      dst.Qta = dst.Qta - 1;
    } else {
      comanda_portate.splice(idx, 1);
    }
    this.Save();
    this.RefreshRiepilogo();
  }

  PortataAggiungi(_mp, idx: number) {
    if (idx < 0) {
      return;
    }
    const comanda_portate = <any[]>this.tmpRiepilogo.comanda_portate;
    var dst = comanda_portate[idx]
    if (dst) {
      dst.Qta = dst.Qta + 1;
    } else {
      dst = Object.assign({ Qta: 1 }, _mp);
      comanda_portate.push(dst);
    }
    this.Save();
    this.RefreshRiepilogo();
  }

  Save() {
    localStorage.setItem('riepilogo', JSON.stringify(this.tmpRiepilogo));
    // var obj = {status: this.isUltimaUscitaModified, uscita: this.ultima_uscita}
    // localStorage.setItem('isModified', JSON.stringify(obj));
  }

}
