import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, SERVIZI, Services } from './app';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dettaglio-fatturazione',
  templateUrl: './dettaglio-fatturazione.component.html'
})
export class DettaglioFatturazioneComponent implements OnInit {

  message: any;
  info: any;

  constructor(
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services,
    public dialogRef: MatDialogRef<DettaglioFatturazioneComponent>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
  }

  async ngOnInit(): Promise<any> {
    if(this.services.user){
      var _info = await this.services.utenti.doc(this.services.user.uid).get().toPromise();
      if (_info.exists === false) {
        this.info = { uid: this.services.user.uid, sync: true };
        _info.ref.set(this.info);
      } else {
        this.info = _info.data();
      }
    }
    else{
      this.info = { 
        nome: "",
        cognome: "",
        displayName: "",
        idCodice: "",
        indirizzo: "",
        numero_civico: "",
        cap:"",
        comune: "",
        provincia: "",
        codice_univoco: "",
        pec: ""
      };
    }
  }

  public Close(result: boolean) {
    this.dialogRef.close(result);
  }

  public Invia(){
    this.message.set({ Command: "f", UserInfo: this.info}).then(() => {
        this.snackbar.open(`Pagamento inviato, comanda completata`, null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top"
        }).afterDismissed().subscribe(() => {
          this.services.prompt(false);
        });
      });
    this.Close(true);
  }

}
