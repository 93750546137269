import { Component, Inject } from '@angular/core';
import { MatAlert } from '@lhn/mat-alert';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html'
})
export class ContattiComponent {

  info: any = {}
  user: any = {};

  Undo() {
    if (this.services.user) {
      this.info.displayName = this.services.user.displayName;
      this.info.email = this.services.user.email;
      this.info.telefono = this.services.user.phoneNumber;
      this.info.uid = this.services.user.uid;
      return;
    }
    const utente = localStorage.getItem('utente');
    if (utente) {
      const _prn = JSON.parse(utente);
      this.info.displayName = _prn.displayName;
      this.info.email = _prn.email;
      this.info.phoneNumber = _prn.phoneNumber;
    }
  }

  constructor(private alert: MatAlert,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.Undo();
  }

  async Salva() {
    if (this.info.phoneNumber && this.info.phoneNumber.startsWith('+') === false) {
      this.info.phoneNumber = `+39${this.info.phoneNumber}`;
    }
    const __info = Object.assign({}, this.info, { //
      sync: true,
      creazione: new Date(),
      uid: this.info.uid || this.info.phoneNumber || this.info.email
    });
    await this.services.contatti.add(__info);
    await this.alert.show('La richiesta è stata inviata', `Riceverà una email di conferma e/o un messaggio WhatsApp.`).toPromise();
    localStorage.setItem('utente', JSON.stringify({ displayName: this.info.displayName, email: this.info.email, phoneNumber: this.info.phoneNumber }));
    this.services.prompt(false);
  }
}
