import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Services, SERVIZI } from './app';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html'
})
export class LoginDialogComponent implements OnInit {
  tab: number;
  username: string;
  password: string;

  constructor(private dialogRef: MatDialogRef<LoginDialogComponent>,
    private angularFireAuth: AngularFireAuth,
    @Inject(SERVIZI) public services: Services) {
    angularFireAuth.authState.subscribe(response => {
      if (response) {
        this.Close();
      }
    });
  }

  isIos(): boolean {
    return ('standalone' in window.navigator) && (window.navigator['standalone']);
  }

  isFacebookApp(): boolean {
    var ua = navigator.userAgent || navigator.vendor || window['opera'];
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  }

  isInstagramApp(): boolean {
    var ua = navigator.userAgent || navigator.vendor || window['opera'];
    return (ua.indexOf("Instagram") > -1);
  }

  isRedirect(): boolean {
    return this.isFacebookApp() || this.isInstagramApp() || this.isIos();
  }

  ngOnInit(): void {
    this.tab = 0;
    this.username = null;
    this.password = null;
  }

  Openid(authProvider) {
    if (this.isRedirect()) {
      this.angularFireAuth.signInWithRedirect(authProvider);
    } else {
      this.angularFireAuth.signInWithPopup(authProvider);
    }
  }

  Facebook() {
    this.Openid(new firebase.auth.FacebookAuthProvider());
  }

  Google() {
    this.Openid(new firebase.auth.GoogleAuthProvider());
  }

  Login() {
    this.angularFireAuth.signInWithEmailAndPassword(this.username, this.password);
  }

  Registrati() {
    this.angularFireAuth.createUserWithEmailAndPassword(this.username, this.password);
  }

  Close() {
    this.dialogRef.close();
  }
}