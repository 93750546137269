import { Component, AfterViewInit, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatAlert } from '@lhn/mat-alert';
import { first } from 'rxjs/operators';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-fatturazione',
  templateUrl: './fatturazione.component.html'
})
export class FatturazioneComponent implements AfterViewInit {

  info: any = {}
  powerUser = false;
  qr: any;

  constructor(private alert: MatAlert, private functions: AngularFireFunctions, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
  @Inject(SERVIZI) public services: Services) { }

  async ngAfterViewInit() {
    var _info = await this.services.utenti.doc(this.services.user.uid).get().toPromise();
    if (_info.exists === false) {
      this.info = { uid: this.services.user.uid, sync: true };
      _info.ref.set(this.info);
    } else {
      this.info = _info.data();
    }
  }

  async Salva() {
    await this.services.utenti.doc(this.services.user.uid).update(Object.assign(this.info, { sync: true }));
    await this.services.user.updateProfile({ displayName: `${this.info.nome} ${this.info.cognome}` });
    this.alert.show('Profilo aggiornato con successo.');
  }
}
