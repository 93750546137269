import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'm40Nome'
})
export class M40NomeCommerciale implements PipeTransform {
    
    transform(value: any, ...args: any[]): any {
        return value.NomeCommerciale || value.Nome;
    }
}
