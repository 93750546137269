import { Component, Inject, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { AngularFireAuth } from '@angular/fire/auth';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { MatDialog } from '@angular/material/dialog';
import { PhotoDialogComponent } from 'src/shared/photo-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  animations: [
    trigger(
      'inOutAnimation1',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class MenuComponent implements OnInit {

  mc: any;
  mcs: any[];
  _mis: any[];
  _mps: any[];
  _mpis: any[];
  user: any;
  src: any;

  async Foto(mp) {
    await this.dialog.open(PhotoDialogComponent, {
      hasBackdrop: false,
      panelClass: 'photo-panel',
      data: mp.Foto
    });
  }

  Piatti(_mc) {
    if (!this._mis) {
      return [];
    }
    return this._mps.filter(_it => _it.Categoria == _mc.Guid);
  }

  Ingredienti(_mp) {
    if (!this._mpis) {
      return [];
    }
    const mis = this._mpis.filter(_it => _it.Piatto == _mp.Guid).map(_it => {
      return this._mis.find(__it => __it.Guid == _it.Ingrediente).Nome;
    });
    return mis;
  }

  constructor(private fireauth: AngularFireAuth, private storage : AngularFireStorage,  private dialog: MatDialog, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    private activatedRoute: ActivatedRoute,
    @Inject(SERVIZI) public services: Services) {
    this.fireauth.authState.subscribe(user => {
      this.user = user;
    });
    this._mps = <any[]>this.configuration.menu_piatto.filter(_mc => _mc.EnableOnLine);
    this.mcs = <any[]>this.configuration.menu_categoria.filter(_mc => _mc.EnableOnLine);
    this._mis = this.configuration.menu_ingrediente;
    this._mpis = this.configuration.menu_piatto_ingrediente;
    this.src = `https://storage.googleapis.com/${(<any>this.storage.storage.app.options).storageBucket}/${configuration.liid}/`
  }
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const mcid = params.get('mcid');
      this.mc = this.mcs.find(_it => _it.Guid === mcid);
    });
  }
}