import { Component, Inject, OnInit } from '@angular/core';
import moment from 'moment';
import { CONFIGURAZIONE, Configurazione, SetupFascia, SetupFasciaChiusura, SetupFasciaConsegna, SetupFasciaAsporto, Services, SERVIZI } from '../shared/app';

@Component({
    selector: 'app-orari',
    templateUrl: './orari.component.html'
})

export class OrariComponent implements OnInit {

    fascia_prenotazione: any;

    constructor(@Inject(CONFIGURAZIONE) public configuration: Configurazione, @Inject(SERVIZI) public services: Services) {
    }

    ngOnInit(): void { 
        var sorted = this.configuration.setup_fascia.sort( (a,b) => {
            // scopo --> Ordinare mettendo la domenica (0) in coda
            var x = a.Giorno;
            var y = b.Giorno;
            if(x == 0) x = 7;
            if(y == 0) y = 7;

            if( x > y)
                return 1;
            if( x < y)
                return -1;
            return a.Inizio - b.Inizio;    
        });
       this.fascia_prenotazione = this.groupBy(sorted, x => x.Giorno);
    }

    Shift(a: number, b: number): number {
        return a << b;
    }

    groupBy<K, V>(array: V[], grouper: (item: V) => K) {
        return array.reduce((store, item) => {
          var key = grouper(item)
          if (!store.has(key)) {
            store.set(key, [item])
          } else {
            store.get(key).push(item)
          }
          return store
        }, new Map<K, V[]>())
    }

    GetValues(giorno: any){
        return this.fascia_prenotazione.get(giorno);
    }

    AsIsOrder(a, b) {
        return 1;
     }
}
