import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CONFIGURAZIONE, Configurazione, SERVIZI, Services } from 'src/shared/app';
import { MatAlert } from '@lhn/mat-alert';

@Component({
  selector: 'app-create-amministratore-prenotazioni-component',
  templateUrl: './create.amministratore.prenotazioni.component.html'
})
export class CreateAmministratorePrenotazioniComponent {

  public prn: any;
  public type: any

  constructor(
    public dialogRef: MatDialogRef<CreateAmministratorePrenotazioniComponent>,
    private alert: MatAlert,
    @Inject(MAT_DIALOG_DATA) public data: any, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
      this.prn = data.prn;
      this.type = data.type;
     }

  public async Close(result : boolean) {
    if(result === true){
      this.Salva();
    }
    this.dialogRef.close(result);
  }

  private async Salva(){
    if (this.prn.phoneNumber.startsWith('+') === false) {
      this.prn.phoneNumber = `+39${this.prn.phoneNumber}`;
    }
    const _ora = this.prn.ora.split(':');
    const __prn = Object.assign({}, { //
      data: this.prn.data,
      ora: parseInt(_ora[0]) * 60 + parseInt(_ora[1]),
      displayName: this.prn.displayName,
      sync: true,
      email: this.prn.email || '',
      area: this.prn.area,
      phoneNumber: this.prn.phoneNumber,
      creazione: new Date(),
      adulti: parseInt(this.prn.adulti),
      bambini: parseInt(this.prn.bambini),
      note: this.prn.note || '',
      stato: this.prn.stato,
      commento: this.prn.commento || '',
      uid: this.prn.phoneNumber || this.prn.email
    });
    try {
      await this.services.prenotazioni.doc(this.prn.id).set(__prn);
      this.alert.show('La prenotazione è stata modificata.');
    } catch {
      this.alert.show('Si è verificato una problema durante il salvataggio.');
    }
  }
}