import { Component, Inject, OnInit } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-utente-carrello',
  templateUrl: './utente.carrello.component.html'
})
export class UtenteCarrelloComponent implements OnInit {

  tmpCart: any;
  guid4: string = '00000004-0000-0000-0000-000000000000';
  status = ['In attesa di conferma', 'Confermata', 'Annullata dal gestore', 'Annullata dal cliente', 'In preparazione', 'Pronto'];

  constructor(private activatedRoute: ActivatedRoute,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
      this.tmpCart =  { comanda_portate: [] };
  }

  Portate() {
    return this.tmpCart.comanda_portate.filter(_it => _it.Guid !== this.guid4);
  }

  Sconti() {
    return this.tmpCart.comanda_portate.filter(_it => _it.Guid === this.guid4);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async params => {
      const cid = params.get('cid');
      const tmpCart = await this.services.firestore.collection("carrelli").doc(cid).get().toPromise();
      if(tmpCart.exists){
        this.tmpCart = tmpCart.data();
      }
    });
  }
}
