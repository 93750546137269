import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CONFIGURAZIONE, Configurazione, SERVIZI, Services } from 'src/shared/app';

@Component({
  selector: 'app-profilo-utente',
  templateUrl: './profilo-utente.component.html'
})
export class ProfiloUtenteComponent implements OnInit {

  viewMode: any;
  screenWidth: any;
  icon: boolean;
  tabList = ["tab1","tab2", "tab3", "tab4", "tab5"];
  amministratore: boolean;
  prenotazioni: boolean;
  ordini: boolean;

  constructor(private route: ActivatedRoute,  @Inject(CONFIGURAZIONE) public configuration: Configurazione,
              @Inject(SERVIZI) public services: Services) {
    
              this.ordini = !!this.configuration.setup_configurazione.Asporto || !!this.configuration.setup_configurazione.Consegna;
              this.prenotazioni = !!this.configuration.setup_configurazione.Prenotazioni;
              this.amministratore = this.services.user && this.services.user.uid === this.configuration.liid && this.prenotazioni
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          const WINDOW_SIZE = 855;
          this.screenWidth = window.innerWidth;
          if(this.screenWidth < WINDOW_SIZE ){
            this.icon = true;
          }
          else this.icon = false;
    }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        if(params.tab && this.tabList.includes(params.tab))
          this.viewMode = params.tab;
        else this.viewMode = "tab1";
      }
    );
    this.icon = false;
    this.getScreenSize();
  }

}
