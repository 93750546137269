import { Component, Inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Configurazione, CONFIGURAZIONE, Services, SERVIZI } from './app';

@Component({
  selector: 'app-root',
  template: `<app-header *ngIf="!essenziale"></app-header>
  <router-outlet></router-outlet>
  <div class="restbeef_fullwidth row row_no_gap" *ngIf="Can()">
    <div class="col-12">
      <div class="absolute-cart-element">
        <button mat-fab class="badge-button"
        (click)="Carrello()"
        [matBadge]="ContentCarrello()"
        matBadgePosition="before" matBadgeColor="accent">
          <mat-icon>shopping_cart</mat-icon>
        </button>
      </div>
    </div>
</div><app-footer *ngIf="!essenziale"></app-footer>`
})

export class AppComponent {

  tmpCart: any;
  essenziale: boolean;

  constructor(swUpdate: SwUpdate,
    snackbar: MatSnackBar,
    private router: Router,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    swUpdate.available.subscribe(() => {
      snackbar.open(`Nuova versione`, null, {
        duration: 2000,
        horizontalPosition: "center",
        verticalPosition: "top"
      }).afterDismissed().subscribe(() => {
        window.location.reload();
      });
    });
    this.essenziale = this.configuration.setup_homepage.VistaEssenziale ?? false;
  }

  Carrello() {
    this.router.navigate(['carrello']);
  }

  Can(){
    var out = !(this.router.url.includes('/m/') || 
    this.router.url.includes('/r/') || 
    this.router.url.includes('/o/') ||
    this.router.url.includes('/asporto') ||
    this.router.url.includes('/carrello')) && this.services.CanCarrello();
    return out;
  }

  ContentCarrello(){
    return this.services.ContentCarrello();
  }

}