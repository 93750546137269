import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'm40time'
})
export class M40TimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const fasce = <any[]>value;
    if (!fasce) {
      return [];
    }
    const data = args[0];
    const outputs = [];
    /**/
    const los = fasce.filter(_f => _f.Giorno === moment(data).weekday() && _f.Stato === 2);
    /**/
    var inizio = 0;
    if (moment(data).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      return outputs;
    }
    if (moment(data).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      const now = moment();
      inizio = now.hours() * 60 + now.minutes();
    }
    /**/
    for (const lo of los) {
      for (var t = lo.Inizio; t <= lo.Fine; t = t + (lo.Durata || 30)) {
        if (t >= inizio) {
          outputs.push(t);
        }
      }
    }
    return outputs;
  }
}
