import { Component, Inject } from '@angular/core';
import { MatAlert } from '@lhn/mat-alert';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { CreateAmministratorePrenotazioniComponent } from './create.amministratore.prenotazioni.component';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { RispondiDialogComponent } from 'src/shared/rispondi-dialog.component';

@Component({
  selector: 'app-amministratore-prenotazioni',
  templateUrl: './amministratore.prenotazioni.component.html'
})
export class AmministratorePrenotazioniComponent {

  public data: Date = null;
  public text: string = null;
  public stato: string = "-1";
  public prns: any[] = [];
  public _prns: any[] = [];
  public status = ['Waiting', 'Confermata', 'Annullata Gestore', 'Annullata Cliente'];
  displayedColumns: string[] = ['status', 'rispondi' ,'modifica', 'data', 'displayName'/*,'dettaglio'*/];
  dataSource: any;

  public Comments = ['Siamo spiacenti, ma per esaurimento dei posti disponibili, non possiamo accettare la sua prenotazione.',
    'Siamo spiacenti, ma a causa delle numerose richieste non è più possibile ricevere ulteriori prenotazioni. Le consigliamo di passare direttamente al locale i tavoli si liberano continuamente. Sarà nostra premura farLa accomodare nel più breve tempo possibile.',
    'Siamo spiacenti, ma a causa di avverse condizioni metereologiche non possiamo accettare la sua prenotazione.',
    'Siamo spiacenti, ma a causa di un imprevisto non possiamo accettare la sua prenotazione.'];

  Sort(n1, n2) {
    if (n1.data > n2.data) {
      return 1;
    }
    if (n1.data < n2.data) {
      return -1;
    }
    if (n1.ora > n2.ora) {
      return 1;
    }
    if (n1.ora < n2.ora) {
      return -1;
    }
    if (n1.creazione > n2.creazione) {
      return 1;
    }
    if (n1.creazione < n2.creazione) {
      return -1;
    }
    return 0;
  }

  async Create() {
    const prn: any = {
      stato: 0,
      adulti: 0,
      bambini: 0,
      seggiolini: 0,
      celiaci: false,
      allergici: false
    };
    const confirm = await this.dialog.open(CreateAmministratorePrenotazioniComponent, {
      // width: '800px',
      // height: '500px',
      panelClass: 'dialog-container-custom',
      data: {
        prn: prn,
        type: 2
      }
    }).afterClosed().toPromise();
    if (!confirm) {
      return;
    }
    if (prn.phoneNumber.startsWith('+') === false) {
      prn.phoneNumber = `+39${prn.phoneNumber}`;
    }
    const _ora = prn.ora.split(':');
    const __prn = Object.assign({}, { //
      data: prn.data,
      ora: parseInt(_ora[0]) * 60 + parseInt(_ora[1]),
      sync: true,
      displayName: prn.displayName,
      email: prn.email || '',
      area: prn.area,
      phoneNumber: prn.phoneNumber,
      creazione: new Date(),
      adulti: parseInt(prn.adulti),
      bambini: parseInt(prn.bambini),
      note: prn.note || '',
      stato: 1,
      commento: prn.commento || '',
      uid: prn.phoneNumber || prn.email
    });
    await this.services.prenotazioni.add(__prn);
    this.alert.show('La prenotazione è stata aggiunta', `Il cliente verrà informato tramite tutti i canali disponibili.`);
  }

  async Rispondi(prn){
    const result = await this.dialog.open(RispondiDialogComponent, {
      panelClass: 'dialog-container-custom',
      hasBackdrop: false,
      data: {
        prn: prn,
      }
    }).afterClosed().toPromise();
    switch(result){
      case 1: {
        this.Conferma(prn);
        break;
      }
      case 2: {
        this.Annulla(prn);
        break;
      }
      default: {
        console.log("Esci");
        break;
      }
    }
  }

  // Type 1 --> Readonly; Type 2 --> Modifica
  async Modifica(prn, type) {
    const confirm = await this.dialog.open(CreateAmministratorePrenotazioniComponent, {
      // width: '800px',
      // height: '500px',
      panelClass: 'dialog-container-custom',
      data: {
        prn: Object.assign({  }, prn),
        type: type
      }
    }).afterClosed().toPromise();
    if (!confirm) {
      return;
    }
  }

  Clear() {
    this.data = null;
    this.Where();
  }

  Where() {
    let _prns = this._prns;
    if (this.data) {
      _prns = _prns.filter((prn) => {
        var eq = this.data === prn.data;
        return eq;
      });
    }
    if (parseInt(this.stato) > -1) {
      _prns = _prns.filter((prn) => {
        let eq0 = prn.stato === parseInt(this.stato);
        return eq0;
      });
    }
    if (this.text) {
      _prns = _prns.filter((prn) => {
        let eq0 = prn.displayName && prn.displayName.indexOf(this.text) > -1;
        let eq1 = prn.email && prn.email.indexOf(this.text) > -1;
        let eq2 = prn.phoneNumber && prn.phoneNumber.indexOf(this.text) > -1;
        return eq0 || eq1 || eq2;
      });
    }
    this.prns = _prns.sort(this.Sort);
    this.dataSource = new MatTableDataSource(this.prns);
  }

  constructor(private alert: MatAlert, private dialog: MatDialog,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    const today = moment().format('YYYY-MM-DD');
    this.services.firestore.collection("prenotazioni", ref => ref.where('data', '>=', today).orderBy('data', 'asc')).snapshotChanges().subscribe(_prns => {
      this._prns = _prns.map(_prn => {
        const _data = <any>_prn.payload.doc.data();
        return Object.assign(_data, {
          id: _prn.payload.doc.id,
          ora: `${(_data.ora / 60) >> 0}:${("0" + (_data.ora % 60)).slice(-2)}`,
          creazione: new Date((_data).creazione.seconds * 1000)
        })
      });
      this.Where();
    });
  }

  async Annulla(prn) {
    const confirm = await this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'dialog-container-custom',
      data: "Sei sicuro?"
    }).afterClosed().toPromise();
    if (confirm) {
      const commento = prn.commento || '';
      await this.services.prenotazioni.doc(prn.id).update({ stato: 2, commento: commento, sync: true });
      prn.stato = 2;
      this.alert.show('La prenotazione è stata annullata', `Il cliente verrà informato tramite tutti i canali disponibili.`);
    }
  }

  async Conferma(prn) {
    const confirm = await this.dialog.open(ConfirmationDialogComponent, {
      
      panelClass: 'dialog-container-custom',
      data: "Sei sicuro?"
    }).afterClosed().toPromise();
    if (confirm) {
      const commento = prn.commento || '';
      await this.services.prenotazioni.doc(prn.id).update({ stato: 1, commento: commento, sync: true });
      prn.stato = 1;
      this.alert.show('La prenotazione è stata confermata', `Il cliente verrà informato tramite tutti i canali disponibili.`);
    }
  }
}
