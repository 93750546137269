import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'm40date'
})
export class M40DatePipe implements PipeTransform {

  search0 = [6, 0, 1, 2, 3, 4, 5];
  search1 = [8, 7, 7, 7, 7, 7, 8];

  exists(day, fasce) : boolean {
    var day0 = this.search0[day];
    var day1 = this.search1[day];
    for (var f = 0; f < fasce.length; f++) {
      const sf = fasce[f];
      if (sf.Giorno === day0 || sf.Giorno === day1) {
        return true;
      }
    }
    return false;
  }

  transform(value: any, ...args: any[]): any {
    const fasce = args[0];
    const inputs = <any[]>value;
    const outputs = [];
    if (fasce) {
      for (var t = 0; t < inputs.length; t++) {
        const day = inputs[t];
        //console.log(day);
        if (this.exists(day.getDay(), fasce)) {
          outputs.push(inputs[t]);
        }
      }
    }
    return outputs;
  }
}
