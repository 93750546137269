import { Component, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, MenuCategoria, Services, SERVIZI, SetupPromozione, SetupPromozioneCategoria } from '../shared/app';
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  animations: [
    trigger('collapse', [
      state('false', style({ height: '0', opacity: '0', visibility: 'hidden' })),
      state('true', style({ height: AUTO_STYLE,  opacity: AUTO_STYLE, visibility: AUTO_STYLE })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class PromoComponent {
  sps: SetupPromozione[];
  spcs: SetupPromozioneCategoria[];
  mcs: MenuCategoria[];
  toggle: boolean;
  constructor(@Inject(CONFIGURAZIONE) public configuration: Configurazione, @Inject(SERVIZI) public services: Services) {
    this.mcs = this.configuration.menu_categoria;
    this.sps = this.configuration.setup_promozione;
    this.spcs = this.configuration.setup_promozione_categoria;
    this.toggle = false;
  }

  Categorie(_sp) {
    const mis = this.spcs.filter(_it => _it.Promozione == _sp.Guid).map(_it => this.mcs.find(__it => __it.Guid == _it.Categoria)).filter(_it => _it).map(_it => _it.Nome);
    if(mis.length === 0){
      return '';
    }
    return `per le seguenti categorie: ${mis.reduce((acc, cur) => `${acc}, ${cur}`)}`;
  }

  BuildString(_sp){
    var prezzo = `${this.AddFasciaDiPrezzo(_sp)}${this.AddData(_sp).length > 0 ? ',': ''}`;
    var data = `${this.AddData(_sp)}${this.AddOra(_sp).length > 0 ? ',': ''}`;
    var ora = `${this.AddOra(_sp)}${this.AddGiorni(_sp).length > 0 ? ',': ''}`;
    var giorni = `${this.AddGiorni(_sp)}${this.Categorie(_sp).length > 0 ? ',': ''}`;
    var categorie = `${this.Categorie(_sp)}`;
    return `Promo attiva ${prezzo} ${data} ${ora} ${giorni} ${categorie}`;
  }

  AddFasciaDiPrezzo(_sp){
    var stringFascia = "";
    if(_sp.SpesaDa && _sp.SpesaA){
      stringFascia = `Ordini compresi tra ${_sp.SpesaDa}€ e ${_sp.SpesaA}€`;
      return stringFascia;
    }
    if(_sp.SpesaDa){
      stringFascia = `Ordini superiori a ${_sp.SpesaDa}€`;
      return stringFascia;
    }
    if(_sp.SpesaA){
      stringFascia = `Ordini inferiori a ${_sp.SpesaA}€`;
      return stringFascia;
    }
    return stringFascia;
  }

  AddData(_sp){
    var stringData= "";
    if(_sp.ValidaDa && _sp.ValidaA){
      stringData = `Dal ${this.services.Data(_sp.ValidaDa)} al ${this.services.Data(_sp.ValidaA)}`;
      return stringData;
    }
    if(_sp.ValidaDa){
      stringData = `Dal ${this.services.Data(_sp.ValidaDa)}}`;
      return stringData;
    }
    if(_sp.ValidaA){
      stringData = `Fino al ${this.services.Data(_sp.ValidaA)}`;
      return stringData;
    }
    return stringData;
  }

  AddGiorni(_sp){
    var stringGiorni = '';
    if(_sp.Giorno && _sp.Giorno == 127){
      stringGiorni = "Tutti i giorni";
    }
    else if(_sp.Giorno){
      stringGiorni = `I giorni: ${this.services.Giorno(_sp.Giorno)}`
    }
    return stringGiorni;
  }

  AddOra(_sp){
    var stringOra= "";
    if(_sp.FasciaDa && _sp.FasciaA){
      stringOra = `Dalle ore ${this.services.Ora(_sp.FasciaDa)} alle ore ${this.services.Ora(_sp.FasciaA)}`;
      return stringOra;
    }
    if(_sp.FasciaDa){
      stringOra = `Dalle ore ${this.services.Ora(_sp.FasciaDa)}}`;
      return stringOra;
    }
    if(_sp.FasciaA){
      stringOra = `Fino alle ore ${this.services.Ora(_sp.FasciaA)}`;
      return stringOra;
    }
    return stringOra;
  }
}