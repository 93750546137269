import { Component, Inject, OnInit } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, MenuCategoria, MenuPiatto, Services, SERVIZI } from 'src/shared/app';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {

  sh: any;
  mps: any[];
  mcs: any[];
  ordini: boolean;

  Url(path: string) {
    return `https://storage.googleapis.com/${(<any>this.storage.storage.app.options).storageBucket}/${this.configuration.liid}/${path}`;
  }

  Foto(index: number) {
    const path = this.sh['Foto' + index];
    return this.Url(path);
  }

  Text(index: number) {
    return this.sh['Text' + index];
  }

  SubText(index: number) {
    return this.sh['SubText' + index];
  }

  Categoria(mc: MenuCategoria) {
    const sc = this.configuration.setup_configurazione;
    const link = (sc.Asporto || sc.Consegna) ? '/asporto' : '/menu';
    this.router.navigate([link, { mcid: mc.Guid }]);
  }

  Piatto(mp: MenuPiatto) {
    const sc = this.configuration.setup_configurazione;
    const link = (sc.Asporto || sc.Consegna) ? '/asporto' : '/menu';
    this.router.navigate([link, { mcid: mp.Categoria }]);
  }

  constructor(private storage: AngularFireStorage, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    private router: Router,
    @Inject(SERVIZI) public services: Services) {
    this.sh = configuration.setup_homepage;
    const _mps = configuration.menu_piatto.filter(_mc => _mc.Homepage);
    this.mps = _mps.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
    const _mcs = configuration.menu_categoria.filter(_mc => _mc.Homepage);
    this.mcs = _mcs.sort((a, b) => 0.5 - Math.random()).slice(0, 4);
    this.ordini = !!this.configuration.setup_configurazione.Asporto || !!this.configuration.setup_configurazione.Consegna;
  }

}
