import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CreaDialogComponent } from 'src/shared/crea-dialog.component';
import { PhotoDialogComponent } from 'src/shared/photo-dialog.component';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from 'src/shared/app';
import { User } from '@firebase/auth-types';
import moment from 'moment';
import { AngularFireStorage } from '@angular/fire/storage';
import { DettaglioMenuPiattoComponent } from 'src/shared/dettaglio-menu-piatto.component';

@Component({
  selector: 'app-asporto',
  templateUrl: './asporto.component.html',
  animations: [
    trigger(
      'inOutAnimation1',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.25s 0.25s ease-out',
              style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.25s 0.25s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class AsportoComponent {

  mc: any;
  user: User;
  _mps: any;
  _mpis: any;
  _mis: any;
  mcs: any[];
  src: any;
  tmpCart: any;

  Piatti(_mc) {
    return this._mps.filter(_it => _it.Categoria == _mc.Guid)
  }

  // Non usato
  async Crea(_mc) {
    const mps = this.configuration.menu_piatto.filter(_it => _it.EnableOnLine && _it.Categoria === _mc.Guid && _it.Base === true);
    const _mcis = this.configuration.menu_categoria_ingrediente.filter(_it => _it.Categoria === _mc.Guid && this._mis.some(_mi => _mi.Guid === _it.Ingrediente && _mi.Base === true));
    const mcis = _mcis.map(mi => Object.assign(this.configuration.menu_ingrediente.filter(_it => _it.Guid === mi.Ingrediente)[0], { Prezzo: mi.Prezzo, Selected: false }));
    await this.dialog.open(CreaDialogComponent, {
      hasBackdrop: false,
      panelClass: 'crea-panel',
      height: '685px',
      data: {
        mps: mps,
        mcis: mcis,
        add: (cp) => this.PortataAggiungi(cp)
      }
    });
  }

  async Dettaglio(_mp){
    const _mcis = this.configuration.menu_categoria_ingrediente.filter(_it => _it.Categoria === this.mc.Guid && this._mis.some(_mi => _mi.Guid === _it.Ingrediente));
    const mis = this._mpis.filter(_it => _it.Piatto == _mp.Guid);
    const mcis = _mcis.filter(_el => mis.some(_it => _it.Ingrediente === _el.Ingrediente) === false).map(mi => Object.assign(this.configuration.menu_ingrediente.filter(_it => _it.Guid === mi.Ingrediente)[0], { Prezzo: mi.Prezzo || 0 , Selected: false }));
    const nmcis = mis.map(mi => Object.assign(this.configuration.menu_ingrediente.filter(_it => _it.Guid === mi.Ingrediente)[0], 
    { Prezzo: this.configuration.menu_categoria_ingrediente.find(_el =>_el.Ingrediente === mi.Ingrediente) ? 
      this.configuration.menu_categoria_ingrediente.find(_el =>_el.Ingrediente === mi.Ingrediente).Prezzo : 0,
      Selected: false 
    }));
    await this.dialog.open(DettaglioMenuPiattoComponent, {
      hasBackdrop: true,
      autoFocus: false,
      panelClass: 'dialog-container-custom',
      data: {
        mp: _mp,
        src: this.src,
        tmpCart: this.tmpCart,
        mcis: mcis,
        nmcis: nmcis,
        sc: this.configuration.setup_configurazione,
        self: false
      }
    });
  }

  // Non usato
  async Foto(mp) {
    await this.dialog.open(PhotoDialogComponent, {
      hasBackdrop: true,
      panelClass: 'photo-panel',
      data: mp.Foto
    });
  }

  // Non usato
  CanCrea(_mc) {
    if (!this._mis) {
      return false;
    }
    return this._mps.filter(_it => _it.Categoria == _mc.Guid && _it.Base === true).length > 0;
  }

  Ingredienti(_mp) {
    const mis = this._mpis.filter(_it => _it.Piatto == _mp.Guid).map(_it => {
      return this._mis.find(__it => __it.Guid == _it.Ingrediente).Nome;
    });
    return mis;
  }

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage : AngularFireStorage,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    const carrello = localStorage.getItem('carrello');
    if (carrello) {
      this.tmpCart = JSON.parse(carrello);
    } else {
      this.tmpCart = { consegna: false};
    }
    this.tmpCart.comanda_portate = this.tmpCart.comanda_portate || [];
    this.mcs = configuration.menu_categoria.filter(_mc => _mc.EnableOnLine);
    this._mps = configuration.menu_piatto.filter(_mc => _mc.EnableOnLine);
    this._mis = configuration.menu_ingrediente;
    this._mpis = configuration.menu_piatto_ingrediente;
    this.src = `https://storage.googleapis.com/${(<any>this.storage.storage.app.options).storageBucket}/${configuration.liid}/`
  }

  // Non usato --> Spostato in dettaglio
  PortataAggiungi(_mp) {
    const comanda_portate = <any[]>this.tmpCart.comanda_portate;
    var dst = comanda_portate.find(_it => _it.Guid === _mp.Guid);
    if (dst) {
      dst.Qta = dst.Qta + 1;
    } else {
      dst = Object.assign({ Qta: 1 }, _mp);
      comanda_portate.push(dst);
    }
    localStorage.setItem('carrello', JSON.stringify(this.tmpCart));
    const _snackbar = this.snackbar.open(`Portata aggiunta: ${dst.Nome} X ${dst.Qta}`, 'Carrello');
    _snackbar.onAction().subscribe(() => {
      this.router.navigate(['carrello']);
    });
  }

  Carrello() {
    this.router.navigate(['carrello']);
  }

  Can(){
    return this.tmpCart.comanda_portate.length > 0;
  }

  ContentCarrello(){
    return this.services.ContentCarrello();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const mcid = params.get('mcid');
      this.mc = this.mcs.find(_it => _it.Guid === mcid);
    });
  }

}