
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './shared/app.module';
import { environment } from './environments/environment';
import ATHS from 'add-to-homescreen-control';

if (environment.production) {
  enableProdMode();
}

import { LOCALE_ID } from '@angular/core';
import { FIREBASE_OPTIONS } from '@angular/fire';
import { CONFIGURAZIONE } from './shared/app';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

function loadFirebase() {
  return environment.production ? fetch('/__/firebase/init.json').then(response => response.json()) : Promise.resolve(environment.firebase);
}

function loadConfiguration(hostname: string) {
  return fetch(`https://storage.googleapis.com/michele-4-0.appspot.com/_/${hostname}.json`).then(response => response.json());
}

function initialize(hostname: string) {
  const manifest = document.createElement('link');
  manifest.href = `https://storage.googleapis.com/michele-4-0.appspot.com/_/${hostname}.manifest.json`;
  manifest.rel = 'manifest';
  document.getElementsByTagName('head')[0].appendChild(manifest);
}

function favicon(_configuration){
  const sc = _configuration.setup_configurazione;
  if(!sc.Icon){
    return;
  }
  const manifest = document.createElement('link');
  manifest.href = `https://storage.googleapis.com/michele-4-0.appspot.com/${_configuration.liid}/icon_192x192.png`;
  manifest.rel = 'shortcut icon';
  manifest.type = 'image/png';
  document.getElementsByTagName('head')[0].appendChild(manifest);
}

function setColorTheme(_configuration){
  const color1 = _configuration.setup_homepage.ColorePrimario ? _configuration.setup_homepage.ColorePrimario : '#b71c1c';
  const color2 = _configuration.setup_homepage.ColoreSecondario ? _configuration.setup_homepage.ColoreSecondario : '#000000';
  const color3 = _configuration.setup_homepage.ColoreTerziario ? _configuration.setup_homepage.ColoreTerziario: '#ffffff';
  document.documentElement.style.setProperty('--first-color', color1);
  document.documentElement.style.setProperty('--second-color', color2);
  document.documentElement.style.setProperty('--third-color', color3);
}

function appInsights(){
  const _appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: 'e81f9621-856d-4b84-96ce-f6d6edf3e63e',
      enableAutoRouteTracking: false
    }
  });
  _appInsights.loadAppInsights();
}

(async () => {
  var hostname = window.location.hostname === 'localhost' ? 'michele-4-0.web.app' : window.location.hostname;
  //var hostname = window.location.hostname === 'localhost' ? 'grande-shanghai.com' : window.location.hostname;
  //var hostname = window.location.hostname === 'localhost' ? 'pizzeriatalia.com' : window.location.hostname;
  //var hostname = window.location.hostname === 'localhost' ? 'www.pizzerialemagnolie.it' : window.location.hostname;
  //var hostname = window.location.hostname === 'localhost' ? 'kooki-cosenza.it' : window.location.hostname;
  if (hostname.startsWith('www.pizzerialemagnolie.it') === false) {
    hostname = hostname.replace('www.', '');
  }
  initialize(hostname);
  ATHS.enable();
  const _firebase = await loadFirebase();
  const _configuration = await loadConfiguration(hostname);
  favicon(_configuration);
  setColorTheme(_configuration);
  appInsights();
  platformBrowserDynamic([
    { provide: FIREBASE_OPTIONS, useValue: _firebase },
    { provide: CONFIGURAZIONE, useValue: _configuration },
    { provide: LOCALE_ID, useValue: 'it-IT' }])
    .bootstrapModule(AppModule);
})();