import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './root.app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from '../app/menu.component';
import { HomeComponent } from '../app/home.component';
import { PrenotaComponent } from '../app/prenota.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { M40TimePipe } from './m40time.pipe';
import { MpisPipe } from './mpis.pipe';
import { HeaderComponent } from '../app/header.component';
import { FooterComponent } from '../app/footer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarConfig, MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { FormsModule } from '@angular/forms';
import { M40DatePipe } from './m40date.pipe';
import { M40FascePipe } from './m40fasce.pipe';
import { NgpSortModule } from "ngp-sort-pipe";
import { registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { MatIconModule } from '@angular/material/icon'
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { MatAlertModule } from '@lhn/mat-alert';
import { PrenotazioniComponent } from '../app/prenotazioni.component';
import { ProfiloComponent } from '../app/profilo.component';
import { CarrelloComponent } from '../app/carrello.component';
import { AsportoComponent } from '../app/asporto.component';
import { AsportiComponent } from '../app/asporti.component';
import { ContattiComponent } from '../app/contatti.component';
import { PrivacyComponent } from '../app/privacy.component';
import { Menu1Component } from '../spa/menu.component';
import { ResumeComponent } from '../spa/resume.component';
import { OkDialogComponent } from 'src/shared/ok-dialog.component';
import { Order1Component } from '../spa/order.component';
import { FontAwesomeModule } from 'ngx-icons';
import { Filter2Pipe, FilterPipe, OrderBy } from '../shared/filter.pipe';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { PhotoDialogComponent } from './photo-dialog.component';
import { CreaDialogComponent } from './crea-dialog.component';
import { PromoComponent } from '../app/promo.component';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from './app';
import { FatturazioneComponent } from '../app/fatturazione.component';
import { AmministratorePrenotazioniComponent } from '../app/amministratore.prenotazioni.component';
import { LoginDialogComponent } from '../shared/login-dialog.component';
import { CreateAmministratorePrenotazioniComponent } from '../app/create.amministratore.prenotazioni.component';
import { StickyHeaderDirective } from './sticky-header.directive';
import { DebounceClickDirective } from './debounce-click.directive';
import { CookieLawModule } from 'angular2-cookie-law';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import ATHS from 'add-to-homescreen-control';
import * as moment from 'moment';
import { Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { NgxPayPalModule } from 'ngx-paypal';
import { UtenteCarrelloComponent } from 'src/app/utente.carrello.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { OrariComponent } from '../app/orari.component';
import { M40NomeCommerciale } from './m40NomeCommerciale.pipe';
import { M40Orario } from './m40Orario.pipe';
import { DettaglioMenuPiattoComponent } from './dettaglio-menu-piatto.component';
import { TitlecasePipe } from './titlecase.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfiloUtenteComponent } from '../app/profilo-utente.component';
import {MatTableModule} from '@angular/material/table';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button'
import { RispondiDialogComponent } from './rispondi-dialog.component';
import { SafePipe } from './safe.pipe';
import { DettaglioFatturazioneComponent } from './dettaglio-fatturazione.component'

registerLocaleData(localeIt, 'it-IT');


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    PrenotaComponent,
    PrenotazioniComponent,
    UtenteCarrelloComponent,
    PromoComponent,
    ContattiComponent,
    M40TimePipe,
    M40DatePipe,
    M40FascePipe,
    MpisPipe,
    HeaderComponent,
    FooterComponent,
    FatturazioneComponent,
    ProfiloComponent,
    CarrelloComponent,
    AsportoComponent,
    AsportiComponent,
    Menu1Component,
    Order1Component,
    AmministratorePrenotazioniComponent,
    ResumeComponent,
    FilterPipe,
    Filter2Pipe,
    OrderBy,
    ConfirmationDialogComponent,
    PhotoDialogComponent,
    CreaDialogComponent,
    CreateAmministratorePrenotazioniComponent,
    StickyHeaderDirective,
    PrivacyComponent,
    LoginDialogComponent,
    DebounceClickDirective,
    OrariComponent,
    M40NomeCommerciale,
    M40Orario,
    OkDialogComponent,
    DettaglioMenuPiattoComponent,
    TitlecasePipe,
    ProfiloUtenteComponent,
    RispondiDialogComponent,
    SafePipe,
    DettaglioFatturazioneComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatBadgeModule,
    BrowserAnimationsModule,
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AngularFireAuthGuardModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgpSortModule,
    MatIconModule,
    MatDatepickerModule,
    MatSliderModule,
    MatSnackBarModule,
    MatCardModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatInputModule,
    MatAlertModule,
    MatDialogModule,
    MatChipsModule,
    MatSelectModule,
    FontAwesomeModule,
    CookieLawModule,
    MatTooltipModule,
    NgxPayPalModule
  ],
  providers: [AngularFireAuthGuard, {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: ['l', 'LL'],
      },
      display: {
        dateInput: 'L',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    },
  },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: REGION, useValue: 'europe-west2' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: <MatSnackBarConfig>{
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 2500
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue:
      {
        width: '648px',
        scrollStrategy: new NoopScrollStrategy()
      }
    },
    {
      provide: SERVIZI, useClass: Services
    }
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    CreateAmministratorePrenotazioniComponent,
    PhotoDialogComponent,
    CreaDialogComponent,
    LoginDialogComponent
  ]
})

export class AppModule {

  ngDoBootstrap(appRef) {
    appRef.bootstrap(AppComponent);
  }

  constructor(fireauth: AngularFireAuth, @Inject(CONFIGURAZIONE) private configuration: Configurazione,
    @Inject(SERVIZI) services: Services) {
    document.title = this.configuration.setup_configurazione.Denominazione;
    services.canPrompt = () => {
      return ATHS.canPrompt;
    };
    services.prompt = force => {
      const today = moment().format('YYYYMMDD');
      const prompted = localStorage.getItem('prompted');
      if (force || !prompted || prompted !== today) {
        localStorage.setItem('prompted', today);
        ATHS.prompt().then(console.log).catch(console.log);
      }
    }
    fireauth.authState.subscribe(user => {
      services.user = user;
    });
  }
}