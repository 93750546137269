import { Pipe, PipeTransform } from '@angular/core';
import { SetupFascia, SetupFasciaChiusura } from './app';
import * as moment from 'moment';

/**
* Questa costante converte in stringa il mese corrispondente
* codificato in base a quanto fornice il metodo month()
* della libreria "moment"
*/
export const mesi = {
    0: 'Gennaio',
    1: 'Febbraio',
    2: 'Marzo',
    3: 'Aprile',
    4: 'Maggio',
    5: 'Giugno',
    6: 'Luglio',
    7: 'Agosto',
    8: 'Settembre',
    9: 'Ottobre',
    10: 'Novembre',
    11: 'Dicembre'
};

/**
* Questa costante codifica i
* i giorni per l'Apertura.
* 
*/
export const giorni = {
    0: 'Domenica',
    1: 'Lunedì',
    2: 'Martedì',
    3: 'Mercoledì',
    4: 'Giovedì',
    5: 'Venerdì',
    6: 'Sabato'  
}

/**
* Questa costante codifica in una maschera di 7 bit
* i giorni scelti per i servizi di Consegna, Asporto e Chiusura.
* 
* ESEMPIO:
*  127 - Tutti i giorni
*  65 - Sabato e Domenica
*/
export const giorni_7bit = {
    1: 'Domenica',
    2: 'Lunedì',
    4: 'Martedì',
    8: 'Mercoledì',
    16: 'Giovedì',
    32: 'Venerdì',
    64: 'Sabato'  
}

@Pipe({
    name: 'm40Orario'
})
export class M40Orario implements PipeTransform {
    
    transform(value: any, ...args: any[]): any {
        
        switch(args[0]) {
            case 'giorno_setup_fascia': return this.giorno_setup_fascia(value);
            
            case 'giorno_altri_setup' : return this.giorno_altri_setup(value, args[1]);
            
            case 'ora': return this.orario(value);
            
            case 'DaA': return this.giorno_Da_A(value);
            
            default: {
                console.log(`m40Orario: Not implemented args=${JSON.stringify(args)}`);
                return null;
            }
        }
    }
    
    /**
    * In questo metodo restituiamo
    * i giorni rappresentati con valori da 0 a 6.
    * @param value 
    */
    private giorno_setup_fascia(value: number): any {
        return giorni[value];
    }
    
    /**
    * Questo metodo restituisce tutti i giorni
    * corrispondenti nella maschera a 7 bit.
    * @param value 
    */
    private get_giorni(value: number) {
        
        const num_Bits: number = 7;
        const giorno = value;
        let giorni: number[] = [];
        
        for (let i = 0; i < num_Bits; i++) {    
            let indice: number = 1;
            if ((giorno >> i) % 2) {
                indice = (indice << i)
                giorni.push(indice);
            }
        }
        
        giorni.sort( (a, b) => {
            return a-b;
        });
        
        /**
        * Il calendario italiano inizia dal lunedì, perciò
        * la Domenica è l'ultimo giorno. Non si fa altro
        * che spostare la domenica all'ultima posizione.
        */
        if (giorni[0]==1) {
            giorni.push(giorni.shift());
        }
        
        return giorni;
    }
    
    /**
    * In questo metodo restituiamo
    * i giorni della Chiusura con la maschera di 7 bit.
    *
    * @param value 
    */
    private giorno_altri_setup(value: number, chiusura?: any): any {  
        
        if (value === 0) return chiusura ? 'Chiuso' : 'Servizio non disponibile';
        if (value === 127) return 'Tutti i giorni';
        
        const giorni = this.get_giorni(value);
        
        let risultato: string = '';
        
        giorni.forEach( (v, i) => {
            if (i<giorni.length-1) {
                risultato = risultato + giorni_7bit[v] + ", ";
            } else {
                risultato = risultato + giorni_7bit[v];
            }
        });
        
        return risultato;
    }
    
    /**
    * In questo metodo convertiamo l'ora di inizio e fine
    * nel formato HH:MM.
    * @param value 
    */
    private orario(value: SetupFascia): string {
        let tmp: number = Math.floor(value.Inizio/60);

        const ora_inizio = tmp < 10 ? `0${tmp}` : `${tmp}`;

        tmp = Math.floor(value.Inizio%60)
        const minuto_inizio = tmp < 10 ? `0${tmp}` : `${tmp}`;
        
        if (!value.Fine) return `Alle ${ora_inizio}:${minuto_inizio}`;
        
        tmp = Math.floor(value.Fine/60);
        const ora_fine = tmp < 10 ? `0${tmp}` : `${tmp}`;
        
        tmp = Math.floor(value.Fine%60);
        const minuto_fine = tmp < 10 ? `0${tmp}` : `${tmp}`;
        
        return `Dalle ${ora_inizio}:${minuto_inizio} alle ${ora_fine}:${minuto_fine}`;
    }
    
    /**
    * In questo metodo rendiamo leggibile
    * l'intervallo delle date DataDa e DataA (se presente).
    * 
    * ESEMPIO: 11 Febbraio 2009
    * @param value 
    */
    private giorno_Da_A(value: SetupFasciaChiusura): string {
        let dataDa = moment(value.DataDa);
        if (value.DataA) {
            let dataA = moment(value.DataA);
            return `${dataDa.date()} ${mesi[dataDa.month()]} ${dataDa.year()} - ${dataA.date()} ${mesi[dataA.month()]} ${dataA.year()}`;
        }
        return `${dataDa.date()} ${mesi[dataDa.month()]} ${dataDa.year()}`;
    }
}
