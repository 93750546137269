import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mpis'
})
export class MpisPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      const inputs = <any[]>value;
      var outputs: string = "";
      for (var t = 0; t < inputs.length; t ++) {
        const name = inputs[t].charAt(0).toUpperCase() + inputs[t].slice(1).toLowerCase();
        outputs += name + ", ";
      }
      return outputs.replace(/,\ $/, "");
    }
    return "";
  }

}
