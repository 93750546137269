import { Component, Inject, OnInit } from '@angular/core';
import { MatAlert } from '@lhn/mat-alert';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import * as moment from 'moment';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-prenota',
  templateUrl: './prenota.component.html'
})
export class PrenotaComponent implements OnInit {
  sc: any;
  token: string = null;
  closed: boolean;

  public Undo() {
    this.prn = {
      stato: 0,
      adulti: 1,
      bambini: 0,
      seggiolini: 0,
      celiaci: false,
      allergici: false
    };
    if (this.services.user) {
      this.prn.displayName = this.services.user.displayName;
      this.prn.email = this.services.user.email;
      this.prn.phoneNumber = this.services.user.phoneNumber;
      this.prn.uid = this.services.user.uid;
      return;
    }
    const utente = localStorage.getItem('utente');
    if (utente) {
      const _utente = JSON.parse(utente);
      this.prn.displayName = _utente.displayName;
      this.prn.email = _utente.email;
      this.prn.phoneNumber = _utente.phoneNumber;
    }
  }

  public stato: number = null;
  public aree: any[] = ['Interno', 'Esterno', 'Indifferente'];
  public zone: any = {};
  public fasce: any[] = [];
  public prn: any = {};
  public min_date;
  public max_date;
  //public fascia: any;

  constructor(private messaging: AngularFireMessaging,
    private alert: MatAlert,
    private router: Router,
    public dialog: MatDialog,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.Undo();
    this.sc = this.configuration.setup_configurazione;
    this.fasce = this.configuration.setup_fascia;

    this.min_date = moment().format('YYYY-MM-DD');
    this.max_date = moment().add(7, 'days').format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.messaging.requestToken.subscribe(token => {
      console.log('Token: ' + token);
      this.token = token;
    });
  }

  Data() {
    if (!this.fasce || !this.prn.data) {
      return;
    }
    // this.fascia = this.fasce.filter(_f => _f.Giorno === moment(this.prn.data).weekday() && _f.Stato === 2); --> Potrebbe servire quando salvo le singole variabili di soglia nella fascia 
    this.closed = this.services.Chiuso(this.prn.data);
  }

  async Conferma() {
    if (this.prn.phoneNumber && this.prn.phoneNumber.startsWith('+') === false) {
      this.prn.phoneNumber = `+39${this.prn.phoneNumber}`;
    }
    const __prn = Object.assign({}, this.prn, { //
      ora: parseInt(this.prn.ora),
      token: this.token,
      sync: true,
      creazione: new Date(),
      uid: this.prn.uid || this.prn.phoneNumber || this.prn.email
    });
    const pid = `${__prn.uid}-${moment(this.prn.data).format('YYYYMMDD')}`;
    const prn = await this.services.prenotazioni.doc(pid).get().toPromise();
    if (prn.exists == true) {
      const question = this.dialog.open(ConfirmationDialogComponent, {
        
        panelClass: 'dialog-container-custom',
        data: "Esiste una prenotazione per quella data. Sovrascrivere?"
      });
      const confirm = await question.afterClosed().toPromise();
      if (!confirm) {
        return;
      }
    }
    var isAccepted = this.sc.AccettazioneAutomatica && await this.AccettazioneAutomatica(__prn);
    if(!isAccepted){
      await this.services.prenotazioni.doc(pid).set(__prn);
      await this.alert.show('La prenotazione è stata creata', `Riceverà una email di conferma e/o un messaggio WhatsApp.`).toPromise();
    }
    localStorage.setItem('utente', JSON.stringify({ displayName: __prn.displayName, email: __prn.email, phoneNumber: __prn.phoneNumber }));
    this.services.prompt(false);
    if (this.services.user) {
      await this.router.navigate(
        ['profilo'], {
        queryParams: { tab: 'tab3' },
        queryParamsHandling: 'merge' }
      );
    } else {
      this.Undo();
    }
  }

  async AccettazioneAutomatica(__prn): Promise<boolean>{
    const pid = `${moment(__prn.data).format('YYYYMMDD')}-${__prn.ora}`;
    const id = `${__prn.uid}-${pid}`;
    const prn_auto = await this.CheckPrenotazioniAutomatiche(pid);
    // Applicare logica
    const totale = __prn.bambini + __prn.adulti;
    if(totale > this.sc.MaxCopertiPerTavolo){
      return false;
    }
    if(totale <= prn_auto.disponibili){
      await this.services.prenotazioni_automatiche.doc(pid).update({ 
        disponibili: prn_auto.disponibili - totale,
        aggiornato: new Date()
      });
      // Mandare messaggio al backend
      const prn = Object.assign({}, __prn, { //
        stato: 1 // Accettata
      });
      await this.services.prenotazioni.doc(id).set(prn);
      await this.alert.show('La prenotazione è andata a buon fine', `Riceverà una email di conferma e/o un messaggio WhatsApp.`).toPromise();
      return true;
    }
    else if (this.sc.RigettaOltreSoglia){
      const prn = Object.assign({}, __prn, { //
        stato: 2 // Rifiutata
      });
      await this.services.prenotazioni.doc(id).set(prn);
      await this.alert.show('La prenotazione non è andata a buon fine', `Non ci sono più posti disponibili per la fascia oraria selezionata`).toPromise();
      return true;
    }
    return false;
  }

  async CheckPrenotazioniAutomatiche(pid: string): Promise<any>{
    const prn_auto = await this.services.prenotazioni_automatiche.doc(pid).get().toPromise();
    if(prn_auto.exists == false){
      var obj = {
        data: moment(this.prn.data).format('YYYY-MM-DD') ?? moment(new Date()).format('YYYY-MM-DD'),
        ora: this.prn.ora,
        disponibili: this.sc.MaxCoperti,
        uid: pid,
        creazione: new Date(),
        aggiornato: new Date()
      };
      await this.services.prenotazioni_automatiche.doc(pid).set(obj);
      return (await this.services.prenotazioni_automatiche.doc(pid).get().toPromise()).data();
    }
    return prn_auto.data();
  }

}