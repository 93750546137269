import { Component, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione } from '../shared/app';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html'
})
export class PrivacyComponent {

  sc : any;

  constructor(@Inject(CONFIGURAZIONE) configuration: Configurazione) {
    this.sc = configuration.setup_configurazione;
  }
}
