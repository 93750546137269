import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecase'
})
export class TitlecasePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value){
      const input = <any> value;
      return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    }
    return "";
  }

}
